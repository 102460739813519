var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-10 offset-md-1"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"w-100 p-3",attrs:{"id":"ticketSubmit","enctype":"multipart/form-data","method":"POST"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"first_name"}},[_vm._v("First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"first_name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form['first_name']),expression:"form['first_name']"}],staticClass:"form-control",attrs:{"name":"first_name","type":"text"},domProps:{"value":(_vm.form['first_name'])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, 'first_name', $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"last_name"}},[_vm._v("Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"last_name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form['last_name']),expression:"form['last_name']"}],staticClass:"form-control",attrs:{"name":"last_name","type":"text"},domProps:{"value":(_vm.form['last_name'])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, 'last_name', $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form['email']),expression:"form['email']"}],staticClass:"form-control",attrs:{"name":"Email","type":"email"},domProps:{"value":(_vm.form['email'])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, 'email', $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"hpno"}},[_vm._v("Mobile Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"pattern":"^[0-9\\-\\+\\s\\(\\)]*$","maxLen":15},on:{"country-changed":_vm.countryChanged},model:{value:(_vm.form['hpno']),callback:function ($$v) {_vm.$set(_vm.form, 'hpno', $$v)},expression:"form['hpno']"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"helptopic"}},[_vm._v("Help Topic "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"helptopic","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form['helptopic']),expression:"form['helptopic']"}],staticClass:"form-control",attrs:{"name":"helptopic"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, 'helptopic', $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.hardcodedHelpTopic),function(ht){return _c('option',{key:ht.id,domProps:{"value":ht.id}},[_vm._v(_vm._s(ht.topic))])}),0),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"subject"}},[_vm._v("Subject "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"subject","rules":{ max: 60, required: true,regex: /^([a-zA-Z0-9$&+,:;=?@#|_\]\['.^*()%!-/{}`~ ])*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form['subject']),expression:"form['subject']"}],staticClass:"form-control",attrs:{"name":"Subject","type":"text"},domProps:{"value":(_vm.form['subject'])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, 'subject', $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"details"}},[_vm._v("Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.form['details']),callback:function ($$v) {_vm.$set(_vm.form, 'details', $$v)},expression:"form['details']"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"subject"}},[_vm._v("Attachment (Only JPG and PNG are accepted. Maximum file size allowed is 5MB)")]),_c('ValidationProvider',{attrs:{"name":"attachment","rules":"size:5000|mimes:image/*"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('input',{ref:"attachment",staticClass:"form-control",attrs:{"type":"file","name":"attachment"},on:{"change":validate}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.sentTicket == true ? '' : errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('vue-recaptcha',{attrs:{"sitekey":"6Ld0Es8ZAAAAAEc9YEvWz4ejWF7l7vTutpBoNFSB","loadRecaptchaScript":true},on:{"expired":_vm.recaptchaExpired,"verify":_vm.verifyRecapcha}}),(_vm.alertRecaptcha == true)?_c('span',{staticClass:"text-danger"},[_vm._v("Recaptcha is required")]):_vm._e()],1)]),(_vm.showSuccessAlert == true)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_vm._v(" Thank you for getting in touch! We have your inquiry and our customer service team will get back to you within 3 business days. ")])])]):_vm._e(),(_vm.errorSend == true)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" There was an error trying to send your message. Please try again later or you can reach out to us at "),_c('a',{staticClass:"link-error",attrs:{"href":"tel:+6564252500"}},[_vm._v("+65 6425 2500")]),_vm._v(". ")])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('button',{staticClass:"btn btn-lg btn-info",attrs:{"disabled":_vm.loading == true ? true : false}},[_vm._v("Send "),(_vm.loading == true)?_c('i',{staticClass:"fa fa-fas fa-spinner fa-spin"}):_vm._e()])])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }